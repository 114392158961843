export const presentation = "Nous sommes un organisme de conseils et de formation professionnelle continue. Implanté à Dakar, nous disposons d’une expertise avérée en ingénierie pédagogique, en conseils et solutions : Relations Internationales, Diplomatie, Organisation Managériale et Leadership."

export const vision = "SONNI GROUP c’est en hommage à Sonni Ali Ber, fondateur du dernier grand empire de l’Afrique de l’Ouest, l’empire du Songhaï̈, connu comme un grand conquérant ayant su donner à la région ouest-africaine ses lettres de noblesses. " +
"La vision de SONNI GROUP s’inscrit dans cette logique, contribuer à l’essor économique du continent et faire briller le potentiel humain partout en Afrique à travers nos services et formations."

export const about = "Nous sommes un organisme de conseils en Relations Internationales, en diplomatie, en organisation managériale et surtout en formation professionnelle continue du droit sénégalais. Ainsi, SONNI GROUP est un cabinet indépendant et pluridisciplinaire." +
    " Pour chaque cas soumis à notre appréciation, SONNI GROUP identifie les meilleurs experts afin de mieux répondre à vos besoins. SONNI vous propose ses services et ses formations professionnelles continues conçues par des experts rompus dans leurs domaines, adaptées à l’évolution mondiale."

export const mission = "Nous croyons au potentiel économique et humain du continent africain. Pour cela, nous nous sommes fixés comme mission de mettre à̀ la disposition des entreprises, organisations (nationales et internationales) et des États africains, les meilleurs conseils et formations avec " +
"les meilleurs experts d’ici et d’ailleurs pour promouvoir le développement économique du continent et outiller leurs collaborateurs à acquérir des nouvelles compétences. Afin d’aboutir à une croissance exceptionnelle."

export const address = "VDN, Liberté 6 Extension, derrière le siège de Wave Sénégal, immeuble Élysée"

export const telephone = "(+221) 33 8014344"

export const mobile = "(+221) 77 216 27 85"

export const email = "contact@sonni-group.com"

export const motDuDG = "Devenez la meilleure version de vous-même, atteignez votre plein potentiel en suivant\n" +
    "nos programmes de formation conçus par des experts rompus dans leurs domaines pour vous permettre d’impacter"

export const citationBilesGate = "La clé du succès dans n’importe quelle entreprise est la capacité des employés à " +
    "apprendre et à s’adapter aux changements rapides. Les leaders doivent investir dans la " +
    "formation continue pour créer des équipes capables de relever les défis de demain."
