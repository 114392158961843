export const teamLearning = "Nos animations team building basé sur du Learning développe les compétences de votre " +
    "équipe en favorisant l'apprentissage collectif. Le programme de « Team Learning de SONNI » " +
    "vous permet de Renforcer la cohésion d'équipe: En travaillant ensemble sur des projets " +
    "communs, apprenant à mieux se connaître, à communiquer et à collaborer, à vivre une " +
    "convivialité hors norme. \n\n\n\n" +
    "En ce sens le team Learning SONNI vous permet :"

export const teamLearningList = [
    {item: "Améliorer la performance de vos équipes en prônant le travail d’équipe ;"},
    {item: "D’évacuer le stress de vos personnels à travers des moments d’ensemble « fun » ;"},
    {item: "De profiter de l’instant présent avec des collègues en sortant de routine bureaucratique ;"},
    {item: "De stimuler l’innovation de vos équipes à travers les partages de connaissances et des idées ;"},
    {item: "D’améliorer la communication ouverte ;"},
    {item: "D’accroitre la motivation de vos équipes à travers le Team Building."},
]

export const experimental = "Notre programme Team Building favorise un apprentissage expérimental. Vos équipes apprennent à travers l’action, les simulations, le tout dans un moment de fun, de joie, d’animation mais surtout de bien-être."

export const cohesion = "Le renforcement de la cohésion d’équipe étant au cœur de nos préoccupations en matière de team building, nos teams builders vous offrent les animations uniques pour renforcer, dynamiser les relations de votre équipe le tout, pour une cohésion plus que renforcée. Permettez un moment meilleur et convivial avec les membres de vos équipes lors de :"

export const cohesionList = [
    {item: "RENCONTRES"},
    {item: "D’AFTERWORKS"},
    {item: "CONVENTIONS"},
]

export const communiquer = "La communication inventive est un type de communication que nous avons conçu pour vos équipes. C’est un style de communication comme motivante, encourageant elle favorise la motivation d’une équipe. " +
"Lors de vos réunions, rencontres, vous aurez besoin d’animation fun, motivante pour reconnaitre la valeur de l’équipe, la recommencer. Le programme Communiquer et Motiver vous favorise :"

export const communiquerList = [
    {item: "De créer un pont entre les employés ;"},
    {item: "D’attendrir les relations avec son équipe ;"},
    {item: "De renforcer les relations avec les partenaires ou collaborateurs..."},
]

