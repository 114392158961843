import { addDoc, collection, serverTimestamp, doc, onSnapshot, setDoc } from 'firebase/firestore';
import React, {useEffect, useState} from 'react';
import Swal from 'sweetalert2'
import {Modal} from "../../components/admin/Modal";
import {db} from "../../config/firebase";
import {DetailModal} from "../../components/admin/DetailModal";
import  Spinner  from "../../components/Spinner";
import ArticleTableItem from "../../components/admin/ArticleTableItem";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    ClassicEditor,
    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';


type formType = {
    id?: string,
    theme: string,
    date: string,
    lieu: string,
    img: string | undefined
}

function Article() {
    const [showModal, setShowModal] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [seminaires, setSeminaires] = useState<any[]>([])
    const [seminaire, setSeminaire] = useState<formType>()
    const [form, setForm] = useState<any>({id: '',titre: '', img: '', content: ''})
    const [edit, isEditing] = useState(false)
    const [loading, isLoading] = useState(false)


    useEffect(() => {

        isLoading(true)
        const subs = onSnapshot(collection(db, "articles"), (snapshot) => {
            let list: any[] = [];
            snapshot.docs.forEach((doc) => {
                list.push({_id: doc.id, ...doc.data()})
            })
            setSeminaires(list)
            isLoading(false)
        })

        return () => {

            subs()
        }

    }, []);

    async function handleSubmit(e: any) {
        e.preventDefault();

        if(!edit) {

            if (form.title && form.img && form.content) {

                await addDoc(collection(db, "articles"), {
                    ...form,
                    timestamps: serverTimestamp()
                })

                setShowModal(!showModal);

                Swal.fire({
                    icon: "success",
                    title: "Articles ajouté avec succès",
                    showConfirmButton: false,
                    timer: 800
                });
                window.location.reload();

            } else alert('Veuillez remplir tous les champs')
        } else {
            if(form.id) {
                try {
                    await setDoc(doc(collection(db, 'articles'), form._id), {...form})
                    setShowModal(!showModal);
                    Swal.fire({
                        icon: "success",
                        title: "Article ajouté avec succès",
                        showConfirmButton: false,
                        timer: 1500
                    });
                } catch (e) {
                    console.log(e)
                }
            }
        }
    }

    function convert2base64(e: React.ChangeEvent<HTMLInputElement>) {
        if(e.target.files != null) {
            const file = e.target.files[0];
            const reader = new FileReader()

            reader.onloadend = () => {

                    setForm({
                        ...form, img: reader?.result?.toString()
                    })

            }

            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            <Modal
                show={showModal}
                onClose={() => {
                    setShowModal(!showModal);
                }}
            >
                <form name="contact"
                      id="contact"
                      onSubmit={handleSubmit}>
                    <h3 className="font-bold mt-3 mb-3 text-primary">{edit ? "Modifier l'articles" : "Formulaire d'ajout d'articles"}</h3>
                    <div className="grid lg:grid-cols-1 gap-4">

                        <div>
                            <p className="mb-3 text-left font-medium !text-gray-900">Titre *</p>
                            <input
                                placeholder="Titre"
                                required
                                name="titre"
                                value={form?.title}
                                className="focus:border-t-gray-900 text-gray min-w-full h-10 rounded border-[1.5px] border-gray-400 p-2"
                                onChange={e => setForm({...form, title: e.target.value})}
                            />
                        </div>


                        <div>
                            <p className="mb-3 text-left font-medium !text-gray-900">Image illustrative *</p>
                            <input
                                placeholder="img"
                                name="affiche"
                                type="file"
                                accept="image/png, image/jpeg"
                                required
                                onChange={e => convert2base64(e)}
                                alt='img'/>
                        </div>
                        {form.img && <img className="h-40 w-40" src={form.img} alt='img'/>}

                        <p className="mb-3 text-left font-medium !text-gray-900">Contenu *</p>
                        <CKEditor
                            data={form.content}
                            onChange={(event, editor) => {
                                const data = editor.getData()
                                setForm((prev: any)=>{return {...prev, content: data}})
                            }}
                            editor={ ClassicEditor }
                            config={ {
                                licenseKey: 'GPL',
                                language: {
                                    ui: 'fr'
                                },

                                toolbar: [
                                    'undo', 'redo', '|',
                                    'heading', '|', 'bold', 'italic', '|',
                                    'link', 'insertTable', 'mediaEmbed', '|',
                                    'bulletedList', 'numberedList', 'indent', 'outdent'
                                ],
                                plugins: [
                                    Bold,
                                    Essentials,
                                    Heading,
                                    Indent,
                                    IndentBlock,
                                    Italic,
                                    Link,
                                    List,
                                    MediaEmbed,
                                    Paragraph,
                                    Table,
                                    Undo
                                ],
                                initialData: "<h1>Contenu de l'article</h1>",
                            } }
                        />

                    </div>

                    <button className="mt-3 px-10 bg-primary h-10 rounded-md">
                        Valider
                    </button>
                </form>
            </Modal>

            {showDetail && seminaire &&
                <DetailModal
                    type="article"
                    show={showDetail}
                    onClose={() => {
                        setShowDetail(!showDetail);
                    }}
                    seminaire={seminaire}
                />
            }

            <section className="flex flex-col mx-auto mt-12">

                <div className="flex justify-between mr-8 mb-3">
                    <h3 className="font-bold text-2xl">Gestion des articles</h3>
                    <div
                        className="flex flex-col hover:bg-secondary p-4 border-t-gray-300 border justify-center h-10 bg-primary align-bottom rounded-lg">
                    <button onClick={() => setShowModal(true)}
                                className="text-secondary text-xs hover:text-white text-center">Ajouter un article
                        </button>
                    </div>
                </div>

                {loading ?
                    <div className="flex flex-1 mt-[20%] justify-center text-center">
                        <Spinner />
                    </div> :
                    seminaires.length > 0 ?
                        <ArticleTableItem
                            seminaires={seminaires}
                            collection="articles"
                            detail={(seminaire: any) => {
                                setSeminaire(seminaire)
                                setShowDetail(true)
                            }}
                        />
                        : <div className="flex flex-1 mt-[20%] justify-center text-center">
                            <h3 className="font-semibold text-2xl text-primary">La liste des articles est vide ! Veuillez en ajouter</h3>
                        </div>
                }

            </section>
        </>

    );
}

export default Article;

