
export const relationInter = "Dans un mondialisé aux enjeux et défis complexe il devient " +
    "nécessaire de s’outiller sur les relations internationales, comprendre " +
    "les contours du domaine afin de pouvoir se situer sur l’évolutions " +
    "des relations entre États. Les séminaires que nous vous proposons " +
    "s’inscrivent dans cette logique."

export const relationInterModules = [
    {
        titre: "Diplomatie Multilatérale et Organisations Internationales",
        objectifs: [
            "Comprendre les mécanismes de la diplomatie multilatérale.",
            "Appréhender le fonctionnement des principales organisations internationales (ONU, UE, UA, etc.).",
            "Développer des stratégies de négociation au sein des instances multilatérales."
        ],
        cible: [
            "Diplomates",
            "Responsables de relations internationales",
            "Représentants d’organisations"
        ]
    },
    {
        titre: "Négociations Internationales : Stratégies et Techniques Avancées",
        objectifs: [
            "Maîtriser les outils et techniques de négociation dans un cadre international.",
            "Gérer les aspects interculturels dans les négociations.",
            "S'entraîner à négocier des accords bilatéraux et multilatéraux."
        ],
        cible: [
            "Diplomates",
            "Cadres dans le commerce international",
            "Leaders d'ONG"
        ]
    },
    {
        titre: "Relations Internationales et Sécurité Globale",
        objectifs: [
            "Comprendre les enjeux de sécurité internationale (terrorisme, cybersécurité, migrations, conflits).",
            "Analyser les politiques de sécurité internationale et les réponses des États.",
            "Élaborer des stratégies de prévention et de gestion des crises."
        ],
        cible: [
            "Personnels des ambassades",
            "Ministères de la défense et de l'intérieur",
            "Organisations internationales"
        ]
    },
    {
        titre: "Le Soft Power et la Diplomatie Culturelle",
        objectifs: [
            "Comprendre le concept de soft power et ses implications en diplomatie.",
            "Appréhender les outils de la diplomatie culturelle.",
            "Élaborer une stratégie d’influence à travers la culture, la langue et l’éducation."
        ],
        cible: [
            "Diplomates",
            "Attachés culturels",
            "Responsables des institutions culturelles"
        ]
    },
    {
        titre: "Gestion des Crises et Médiation Internationale",
        objectifs: [
            "Acquérir les techniques de gestion des crises internationales.",
            "Développer des compétences en médiation et facilitation de dialogues entre États.",
            "Étudier des cas concrets de résolution de crises."
        ],
        cible: [
            "Diplomates",
            "Personnels des ONG",
            "Experts en gestion de conflits"
        ]
    },
    {
        titre: "Économie et Relations Internationales : Politiques et Enjeux Mondiaux",
        objectifs: [
            "Comprendre l’interaction entre l’économie et la diplomatie.",
            "Maîtriser les outils d’analyse des politiques économiques internationales.",
            "Développer des stratégies pour promouvoir les intérêts économiques d’un État."
        ],
        cible: [
            "Diplomates",
            "Cadres d'entreprises internationales",
            "Experts en commerce international"
        ]
    },
    {
        titre: "Introduction à la ZLECAF : Comprendre les Enjeux et Opportunités",
        objectifs: [
            "Introduire les participants aux fondements de la ZLECAF, ses objectifs, ses principes, et les mécanismes de mise en œuvre.",
            "Identifier les avantages et les opportunités économiques pour les pays africains, notamment en termes de commerce intra-africain.",
            "Expliquer le cadre légal et institutionnel qui régit la ZLECAF, et son interaction avec d’autres accords commerciaux régionaux et internationaux."
        ],
        cible: [
            "Diplomates",
            "Représentants des ministères du commerce",
            "Cadres des organisations internationales et ONG"
        ]
    },
    {
        titre: "Impact de la ZLECAF sur les PME Africaines : Opportunités et Défis",
        objectifs: [
            "Identifier les opportunités offertes par la ZLECAF pour les petites et moyennes entreprises (PME) africaines, en particulier en matière d'exportation et d'accès à de nouveaux marchés.",
            "Analyser les défis spécifiques rencontrés par les PME, notamment en termes de compétitivité, normes et régulations.",
            "Fournir des outils pratiques pour que les PME puissent s'adapter aux nouvelles exigences du commerce continental et tirer parti de la ZLECAF."
        ],
        cible: [
            "Entrepreneurs",
            "Dirigeants de PME",
            "Chambres de commerce",
            "Agences de développement économique"
        ]
    }
];





export const formationDiplomatie = "La diplomatie, partie de la politique qui concerne les " +
    "relations entre les États : représentation des intérêts d'un " +
    "gouvernement à l'étranger, administration des affaires " +
    "internationales, direction et exécution des négociations " +
    "entre États (ambassade, légation, consulat). Nos " +
    "séminaires vous permettent de cerner les contours du " +
    "domaine."

export const formationDiplomatieModules = [
    {
        titre: "Initiation à la Diplomatie et aux Relations Internationales",
        objectifs: [
            "Comprendre les fondements de la diplomatie et des relations internationales.",
            "Se familiariser avec les acteurs de la diplomatie : États, organisations internationales, ONG.",
            "Maîtriser les fonctions diplomatiques et le rôle des ambassades."
        ],
        cible: [
            "Nouveaux diplomates",
            "Fonctionnaires des ministères des affaires étrangères",
            "Étudiants en relations internationales"
        ]
    },
    {
        titre: "Diplomatie Publique et Gestion de l’Image d’un État",
        objectifs: [
            "Développer des stratégies pour promouvoir l’image et les intérêts d’un État à l’étranger.",
            "Utiliser les outils de communication moderne, y compris les réseaux sociaux, pour influencer l’opinion publique internationale.",
            "Gérer les relations avec les médias dans un contexte diplomatique."
        ],
        cible: [
            "Attachés de presse",
            "Diplomates",
            "Responsables communication des ambassades"
        ]
    },
    {
        titre: "Techniques de Négociation Diplomatique",
        objectifs: [
            "Maîtriser les techniques de négociation bilatérale et multilatérale.",
            "Apprendre à naviguer dans les discussions interculturelles et surmonter les barrières culturelles.",
            "Participer à des simulations de négociations internationales pour développer les compétences pratiques."
        ],
        cible: [
            "Diplomates",
            "Fonctionnaires des organisations internationales",
            "Négociateurs d’ONG"
        ]
    },
    {
        titre: "Éthique et Pratiques en Diplomatie",
        objectifs: [
            "Aborder les enjeux éthiques et les dilemmes rencontrés par les diplomates.",
            "Développer une compréhension des responsabilités et du cadre moral en diplomatie.",
            "Analyser des cas concrets d'éthique diplomatique et de conflits d'intérêts."
        ],
        cible: [
            "Diplomates",
            "Responsables des affaires internationales",
            "Personnels des ambassades"
        ]
    },
    {
        titre: "Diplomatie Préventive et Gestion des Conflits",
        objectifs: [
            "Comprendre les mécanismes de la diplomatie préventive dans la gestion des conflits.",
            "Identifier et analyser les causes des conflits internationaux.",
            "Développer des stratégies pour prévenir, gérer et résoudre les conflits à travers la diplomatie."
        ],
        cible: [
            "Diplomates",
            "Médiateurs",
            "Personnels d’organisations internationales et régionales"
        ]
    },
    {
        titre: "Le Protocole Diplomatique et les Relations Interétatiques",
        objectifs: [
            "Comprendre les règles du protocole diplomatique et leur importance dans les relations internationales.",
            "Gérer les cérémonies, réceptions et visites officielles.",
            "Maîtriser les pratiques interculturelles en diplomatie."
        ],
        cible: [
            "Diplomates",
            "Responsables de protocoles",
            "Chargés des relations extérieures"
        ]
    },
    {
        titre: "Diplomatie Digitale : Nouvelles Technologies et Communication Diplomatique",
        objectifs: [
            "Utiliser les outils digitaux pour mener une diplomatie moderne.",
            "Exploiter les médias sociaux pour diffuser des messages diplomatiques et gérer l'image d'un pays.",
            "Apprendre à faire face aux cybermenaces dans un contexte diplomatique."
        ],
        cible: [
            "Diplomates",
            "Responsables communication",
            "Experts en cybersécurité"
        ]
    }
];



export const affaire = "L’impact de la diplomatie d’Affaires s’est avérée nécessaires pour " +
    "les grandes entreprises, multinationales qui ont besoin de trouver " +
    "des facilités pour leur expansion. Elle est lien entre les " +
    "multinationales et les États, représentations diplomatiques. Sa " +
    "compréhension est donc très importante pour une meilleure " +
    "expansion."

export const affairesModules = [
    {
        titre: "Stratégies de Diplomatie d’Affaires en Afrique : Comprendre les Enjeux Politiques et Économiques",
        objectifs: [
            "Comprendre les dynamiques politiques, économiques et socioculturelles qui influencent les affaires en Afrique.",
            "Développer des stratégies efficaces pour gérer les relations avec les gouvernements, les régulateurs et les acteurs locaux.",
            "Identifier les opportunités et les risques politiques pour les investissements en Afrique."
        ],
        cible: [
            "Directeurs des affaires publiques",
            "Responsables des relations institutionnelles",
            "Dirigeants de multinationales"
        ]
    },
    {
        titre: "Négociation et Gestion des Conflits dans un Contexte Multiculturel en Afrique",
        objectifs: [
            "Apprendre à négocier efficacement avec des partenaires publics et privés africains en tenant compte des différences culturelles.",
            "Gérer les conflits potentiels avec les parties prenantes locales, y compris les gouvernements et les communautés.",
            "Maîtriser les techniques de médiation pour résoudre des différends liés à l'implantation des multinationales."
        ],
        cible: [
            "Négociateurs d’affaires",
            "Responsables des relations externes",
            "Cadres des multinationales"
        ]
    },
    {
        titre: "L’Art du Lobbying et des Relations Gouvernementales en Afrique",
        objectifs: [
            "Apprendre à construire et à maintenir des relations productives avec les gouvernements africains.",
            "Comprendre les régulations et politiques locales qui influencent les investissements étrangers.",
            "Développer des stratégies de lobbying éthiques pour influencer les politiques économiques locales."
        ],
        cible: [
            "Responsables des relations gouvernementales",
            "Cadres des affaires publiques",
            "Directeurs régionaux"
        ]
    },
    {
        titre: "Gérer les Risques Géopolitiques et Économiques en Afrique pour les Multinationales",
        objectifs: [
            "Comprendre les risques géopolitiques et économiques majeurs qui affectent les multinationales en Afrique.",
            "Développer des stratégies de gestion proactive des crises liées à l'instabilité politique ou aux changements réglementaires.",
            "Analyser des études de cas sur des multinationales opérant en Afrique et leur gestion des risques."
        ],
        cible: [
            "Directeurs de la gestion des risques",
            "Responsables de la stratégie d’entreprise",
            "Cadres des multinationales"
        ]
    },
    {
        titre: "Le Soft Power des Multinationales : Renforcer l’Impact Local et les Relations avec les Communautés",
        objectifs: [
            "Comprendre comment les entreprises peuvent utiliser le soft power pour améliorer leur image et influencer leur environnement.",
            "Renforcer les relations avec les communautés locales à travers des initiatives de responsabilité sociale des entreprises (RSE).",
            "Développer des partenariats stratégiques avec des acteurs locaux pour soutenir les objectifs de l'entreprise."
        ],
        cible: [
            "Responsables RSE",
            "Directeurs de la communication",
            "Responsables des affaires institutionnelles"
        ]
    },
    {
        titre: "Innovation Diplomatique : Adaptation des Multinationales aux Défis de l’Afrique Digitale",
        objectifs: [
            "Explorer l'impact de la révolution numérique sur les affaires et la diplomatie d’entreprise en Afrique.",
            "Identifier les opportunités et les défis pour les entreprises dans l’économie numérique africaine.",
            "Élaborer des stratégies de diplomatie d’affaires pour s'adapter aux nouveaux écosystèmes digitaux."
        ],
        cible: [
            "Responsables de la transformation digitale",
            "Directeurs de l’innovation",
            "Cadres en stratégie"
        ]
    }
];


export const economique = "La Diplomatie économique représente la politique étrangère d’un " +
    "État faisant la liaison entre les démarches de politique étrangère et " +
    "la promotion des intérêts économiques du pays. En ce sens, c’est " +
    "par les instruments de politique étrangère en vue d’une meilleure " +
    "attirance des investisseurs et autres dans le pays. La comprendre " +
    "s’avère nécessaire pour les États, organisations internationales, les " +
    "entreprises."

export const economieModules = [
    {
        titre: "Introduction à la Diplomatie Économique",
        objectifs: [
            "Comprendre les fondements de la diplomatie économique et son rôle dans la promotion des intérêts économiques d’un pays.",
            "Maîtriser les relations entre diplomatie, commerce international et attractivité des investissements étrangers.",
            "Identifier les acteurs clés de la diplomatie économique : États, organisations internationales, entreprises."
        ],
        cible: [
            "Diplomates",
            "Attachés commerciaux",
            "Responsables des affaires économiques",
            "Cadres des ministères du commerce et des affaires étrangères"
        ]
    },
    {
        titre: "Stratégies de Promotion des Investissements Directs Étrangers (IDE)",
        objectifs: [
            "Élaborer des stratégies pour attirer les investissements directs étrangers (IDE) dans un pays ou une région.",
            "Maîtriser les outils de promotion économique à l’étranger : événements internationaux, foires commerciales, missions économiques.",
            "Gérer les relations avec les investisseurs étrangers et proposer des cadres juridiques et fiscaux attractifs."
        ],
        cible: [
            "Responsables des agences de promotion des investissements",
            "Diplomates économiques",
            "Cadres des ministères du commerce et de l’économie"
        ]
    },
    {
        titre: "Diplomatie Économique Bilatérale : Renforcer les Relations Commerciales entre États",
        objectifs: [
            "Développer des compétences pour négocier des accords bilatéraux de commerce et d'investissement.",
            "Identifier les leviers pour renforcer les relations économiques bilatérales, notamment dans les secteurs stratégiques.",
            "Analyser des études de cas sur des accords bilatéraux réussis et comprendre les enjeux de leur mise en œuvre."
        ],
        cible: [
            "Diplomates",
            "Négociateurs économiques",
            "Cadres des ministères du commerce extérieur et des affaires étrangères"
        ]
    },
    {
        titre: "Négociation des Accords Commerciaux Multilatéraux",
        objectifs: [
            "Maîtriser les techniques de négociation dans un cadre multilatéral, notamment au sein de l'OMC et d'autres organisations régionales.",
            "Comprendre les enjeux spécifiques des accords commerciaux et leur impact sur l’économie locale et internationale.",
            "Analyser les accords existants et identifier les avantages et risques pour les États et entreprises."
        ],
        cible: [
            "Diplomates",
            "Négociateurs d’accords commerciaux",
            "Responsables des relations internationales"
        ]
    },
    {
        titre: "Promotion des Exportations et Diplomatie Commerciale",
        objectifs: [
            "Développer des stratégies efficaces pour promouvoir les produits et services nationaux à l’étranger.",
            "Maîtriser les outils de la diplomatie commerciale pour soutenir les entreprises locales dans leur expansion internationale.",
            "Créer des réseaux et des partenariats pour faciliter l'accès aux marchés étrangers."
        ],
        cible: [
            "Diplomates",
            "Responsables d’agences de promotion des exportations",
            "Cadres des chambres de commerce"
        ]
    },
    {
        titre: "Le Rôle des Ambassades dans la Diplomatie Économique : Attractivité et Opportunités d’Investissement",
        objectifs: [
            "Comprendre le rôle des ambassades dans la promotion économique d’un pays à l’étranger.",
            "Élaborer des stratégies pour renforcer l’attractivité d’un pays auprès des investisseurs et des entreprises internationales.",
            "Apprendre à organiser des événements économiques et des rencontres bilatérales pour promouvoir les échanges commerciaux."
        ],
        cible: [
            "Ambassadeurs",
            "Attachés économiques",
            "Diplomates",
            "Responsables d’agences de développement"
        ]
    },
    {
        titre: "Diplomatie Économique et Développement Durable",
        objectifs: [
            "Intégrer les objectifs de développement durable (ODD) dans la diplomatie économique.",
            "Promouvoir les investissements étrangers tout en tenant compte des enjeux environnementaux et sociaux.",
            "Développer des stratégies de diplomatie verte pour attirer les investissements responsables et durables."
        ],
        cible: [
            "Diplomates",
            "Responsables du développement durable",
            "Cadres d’agences de promotion des investissements"
        ]
    },
    {
        titre: "Diplomatie Économique Digitale : Opportunités et Défis dans l’Économie Numérique",
        objectifs: [
            "Comprendre l’impact de la transformation numérique sur la diplomatie économique.",
            "Identifier les opportunités liées à l’économie digitale : commerce électronique, fintech, technologie blockchain.",
            "Élaborer des stratégies de diplomatie économique pour promouvoir les entreprises digitales et attirer les investisseurs technologiques."
        ],
        cible: [
            "Diplomates",
            "Responsables d’agences de promotion des investissements",
            "Cadres du secteur technologique"
        ]
    }
];


export const humanitaire = "Pour répondre aux besoins cruciaux de l’humanitaire, le " +
    "professionnel a besoin de mieux s’outiller. Nos experts ont mis en " +
    "place des programmes de formations pour vous permettre une " +
    "meilleure compréhension et action en tant qu’intervenant en " +
    "matière humanitaire."

export const humanitaireModules = [
    {
        titre: "Gestion des Crises Humanitaires en Afrique",
        objectifs: [
            "Comprendre les principales crises humanitaires en Afrique (conflits, catastrophes naturelles, épidémies) et leurs impacts sur les populations.",
            "Apprendre à coordonner des actions humanitaires en période de crise, en collaboration avec les organisations internationales, les ONG, et les gouvernements locaux.",
            "Développer des compétences en planification d'urgence, en gestion des ressources et en logistique humanitaire."
        ],
        cible: [
            "Cadres des organisations humanitaires",
            "Responsables de projets d’aide d’urgence",
            "Représentants des gouvernements et des ONG"
        ]
    },
    {
        titre: "Coordination Humanitaire et Gestion des Partenariats",
        objectifs: [
            "Maîtriser les mécanismes de coordination entre les acteurs humanitaires pour améliorer l’efficacité des interventions.",
            "Développer des partenariats stratégiques pour une réponse humanitaire efficace, notamment dans les zones de conflits.",
            "Apprendre à gérer les relations avec les donateurs, les autorités locales et les communautés affectées."
        ],
        cible: [
            "Responsables de programmes humanitaires",
            "Cadres des ONG",
            "Diplomates en charge de la coopération humanitaire"
        ]
    },
    {
        titre: "Protection des Populations Vulnérables et Droit International Humanitaire",
        objectifs: [
            "Comprendre le cadre juridique international qui régit les actions humanitaires.",
            "Développer des stratégies pour protéger les populations les plus vulnérables en période de crise.",
            "Étudier des cas pratiques pour mieux comprendre les défis de la protection des civils dans les contextes africains."
        ],
        cible: [
            "Travailleurs humanitaires",
            "Diplomates",
            "Cadres des ministères de l'intérieur et de la justice",
            "Agents des agences internationales"
        ]
    },
    {
        titre: "Logistique Humanitaire et Gestion des Approvisionnements en Afrique",
        objectifs: [
            "Apprendre à planifier et à gérer la logistique des opérations humanitaires dans des environnements complexes.",
            "Développer des compétences en gestion des stocks, distribution des biens et gestion des flux de marchandises dans les zones de crise.",
            "Maîtriser les techniques de transport humanitaire, y compris l’utilisation des corridors humanitaires."
        ],
        cible: [
            "Cadres logistiques des organisations humanitaires",
            "Responsables de la chaîne d’approvisionnement",
            "Opérateurs humanitaires sur le terrain"
        ]
    },
    {
        titre: "Santé Humanitaire : Réponse aux Épidémies et Crises Sanitaires",
        objectifs: [
            "Comprendre les enjeux de santé dans les contextes humanitaires africains, avec un focus sur les épidémies et les crises sanitaires.",
            "Maîtriser les protocoles d'intervention d'urgence sanitaire.",
            "Apprendre à coordonner les actions entre les services de santé locaux, les agences internationales et les ONG."
        ],
        cible: [
            "Travailleurs de santé",
            "Responsables des programmes de santé humanitaire",
            "Agents de l'OMS et autres agences sanitaires"
        ]
    },
    {
        titre: "Gestion des Camps de Réfugiés et Personnes Déplacées",
        objectifs: [
            "Maîtriser les techniques de gestion des camps de réfugiés et de déplacés internes.",
            "Apprendre à évaluer les besoins des populations déplacées et à coordonner les interventions dans les camps.",
            "Gérer les relations avec les autorités locales et internationales, les organisations de soutien et les communautés affectées."
        ],
        cible: [
            "Gestionnaires de camps",
            "Agents humanitaires",
            "Responsables des services sociaux et de protection civile"
        ]
    },
    {
        titre: "Humanitaire et Développement Durable : Stratégies pour une Aide Humanitaire Durable",
        objectifs: [
            "Intégrer les objectifs de développement durable dans les interventions humanitaires pour maximiser leur impact à long terme.",
            "Développer des stratégies qui allient aide humanitaire immédiate et développement à long terme.",
            "Étudier des cas de collaboration entre acteurs humanitaires et de développement pour renforcer la résilience des communautés."
        ],
        cible: [
            "Responsables de programmes humanitaires",
            "Experts en développement durable",
            "ONG",
            "Agences internationales"
        ]
    },
    {
        titre: "Genre et Action Humanitaire : Protection des Femmes et des Filles en Situation de Crise",
        objectifs: [
            "Comprendre les enjeux spécifiques auxquels sont confrontées les femmes et les filles dans les contextes de crise humanitaire.",
            "Développer des programmes humanitaires sensibles au genre et adaptés aux besoins particuliers des femmes.",
            "Apprendre à intégrer une perspective de genre dans toutes les phases des interventions humanitaires."
        ],
        cible: [
            "Responsables de programmes humanitaires",
            "Experts en genre",
            "Travailleurs sociaux",
            "ONG de défense des droits des femmes"
        ]
    }
];


export const leadership = "Pour répondre aux besoin actuels du monde professionnelle, le " +
    "manager, au-delà des notions managériales doit développer son " +
    "leadership, la connaitre et pouvoir motiver son équipe pour une " +
    "performance exceptionnelle. C’est justement ce que nos formations " +
    "vous proposent."

export const leadershipModules = [
    {
        titre: "Séminaire en Prise de parole en public",
        objectifs: "Développer les capacités des leaders, managers, professionnels, cadres à pouvoir s’exprimer de la façon la plus aisée pour impacter.",
        contenu: [
            "Exercice de leadership sur la connaissance de soi, confiance en soi",
            "Les outils de la prise de parole en public",
            "Pratique de la prise de parole en public"
        ],
        cible: ["Tout professionnel désirant s’améliorer en prise de parole en public"]
    },
    {
        titre: "Séminaire : Leadership stratégique et visionnaire",
        objectifs: "Développer la capacité des leaders à élaborer une vision claire et à prendre des décisions stratégiques alignées sur les objectifs de l’entreprise.",
        contenu: [
            "Élaboration et communication de la vision stratégique",
            "Analyse de l'environnement et anticipation des tendances futures",
            "Alignement des équipes sur la stratégie de l’entreprise",
            "Leadership décisionnel dans des environnements complexes"
        ],
        cible: ["Dirigeants", "cadres supérieurs", "responsables de la stratégie"]
    },
    {
        titre: "Séminaire : Leadership collaboratif et gestion d’équipe",
        objectifs: "Former les leaders à encourager la collaboration, la cohésion et la gestion efficace des équipes.",
        contenu: [
            "Leadership participatif et décision collective",
            "Renforcer la cohésion et la collaboration dans les équipes",
            "Techniques pour gérer les équipes multiculturelles et multidisciplinaires",
            "Gestion des conflits et communication constructive"
        ],
        cible: ["Managers", "chefs d’équipe", "chefs de projets"]
    },
    {
        titre: "Séminaire : Leadership en temps de crise",
        objectifs: "Fournir aux leaders les compétences nécessaires pour gérer les crises tout en maintenant la motivation et la performance de l’équipe.",
        contenu: [
            "Prise de décision sous pression",
            "Stratégies de communication de crise",
            "Gérer l’incertitude et maintenir la résilience de l’équipe",
            "Transformer les crises en opportunités"
        ],
        cible: ["Cadres dirigeants", "responsables de la gestion des risques", "managers opérationnels"]
    },
    {
        titre: "Séminaire : Leadership émotionnel et intelligence relationnelle",
        objectifs: "Développer l’intelligence émotionnelle des leaders pour améliorer les relations interpersonnelles et la gestion des équipes.",
        contenu: [
            "Comprendre et maîtriser ses propres émotions",
            "Développer l'empathie et la gestion des relations interpersonnelles",
            "Techniques de gestion du stress et des conflits",
            "Renforcement de la confiance et de la communication dans l’équipe"
        ],
        cible: ["Managers", "chefs d’équipe, professionnels souhaitant améliorer leur leadership"]
    },
    {
        titre: "Coaching en leadership et développement personnel",
        objectifs: "Aider les leaders à développer leur potentiel personnel et à renforcer leur influence au sein de leurs équipes.",
        contenu: [
            "Connaissance de soi et gestion des émotions",
            "Techniques d’assertivité et communication impactante",
            "Identifier et développer son style de leadership",
            "Plan de développement personnel et professionnel"
        ],
        cible: ["Managers", "cadres supérieurs",  "dirigeants"]
    },
    {
        titre: "Coaching en gestion du stress et bien-être au travail",
        objectifs: "Apprendre aux professionnels à gérer le stress pour maintenir un équilibre mental et améliorer la productivité.",
        contenu: [
            "Identifier les sources de stress et les symptômes",
            "Techniques de gestion du stress (méditation, pleine conscience, respiration)",
            "Création d’un plan de gestion du stress au quotidien",
            "Stratégies pour améliorer le bien-être au travail"
        ],
        cible: ["Tous les professionnels, en particulier ceux exposés à des environnements stressants"]
    },
    {
        titre: "Coaching en gestion des équipes et communication",
        objectifs: "Améliorer les compétences de communication des managers pour une gestion efficace des équipes.",
        contenu: [
            "Techniques de communication active et assertive",
            "Gestion des conflits et des divergences au sein des équipes",
            "Développement de la collaboration et de la synergie d’équipe",
            "Feedback constructif et coaching individuel des membres de l’équipe"
        ],
        cible: ["Managers", "chefs d’équipe"]
    },
    {
        titre: "Optimisation de la performance individuelle et d'équipe",
        objectifs: "Améliorer la productivité individuelle et collective au sein des équipes.",
        contenu: [
            "Évaluation des performances individuelles et collectives",
            "Techniques pour définir des objectifs SMART",
            "Outils de gestion du temps et des priorités",
            "Stratégies de motivation et de reconnaissance au sein des équipes"
        ],
        cible: ["Managers", "membres d'équipes"]
    },
    {
        titre: "Gestion du temps et des priorités",
        objectifs: "Aider les participants à mieux gérer leur temps pour accroître leur efficacité.",
        contenu: [
            "Identifier et analyser les voleurs de temps",
            "Outils et techniques de planification (matrice d'Eisenhower, Pomodoro)",
            "Établissement de priorités et délégation efficace",
            "Création d’un plan d’action personnel"
        ],
        cible: "Professionnels de tous niveaux, en particulier ceux avec des charges de travail élevées"
    },
    {
        titre: "Leadership et développement des compétences managériales",
        objectifs: "Former les futurs leaders à optimiser leur impact sur la performance de leurs équipes.",
        contenu: [
            "Styles de leadership et leur impact sur la performance",
            "Techniques de motivation et de développement des talents",
            "Prise de décision efficace et gestion des conflits",
            "Élaboration d’un plan de développement personnel pour le leadership"
        ],
        cible: ["Managers", "superviseurs", "futurs leaders"]
    },
    {
        titre: "Innovation et créativité en milieu professionnel",
        objectifs: "Stimuler la créativité et l’innovation pour améliorer la performance des équipes.",
        contenu: [
            "Techniques pour favoriser la pensée créative (brainstorming, mind mapping)",
            "Gestion de l’innovation et mise en œuvre des idées",
            "Études de cas d’entreprises innovantes",
            "Création d’un plan d’action pour intégrer l’innovation dans le quotidien professionnel"
        ],
        cible: ["Équipes de projet", "responsables R&D", "professionnels souhaitant innover"]
    }
];


export const bureautique = "Le client est roi, dit-on, comprendre l’accueil est important dans une " +
    "organisation. Au-delà, maitriser les notions bureautiques et " +
    "d’administration permettent une agilité dans le travail. C’est " +
    "pourquoi nous vous proposons des formations dans ce sens."

export const bureautiqueModules = [
    {
        titre: "Optimisation de la fonction de secrétaires et assistantes par les TICS",
        objectifs: "Maitriser les compétences en bureautique pour optimiser les tâches administratives et améliorer la productivité.",
        contenu: [
            "Utilisation des outils bureautiques",
            "Gestion de messages et comptes rendus",
            "Fonctions du pack Office pour améliorer la productivité"
        ],
        cible: [
            "Assistants et secrétaires de direction",
            "Secrétaires expérimentés souhaitant évoluer",
            "Assistants débutants souhaitant gagner en professionnalisme"
        ]
    },
    {
        titre: "Apport des TICS dans les fonctions administratives",
        objectifs: "Intégrer des outils informatiques dans les fonctions administratives pour gagner en efficacité.",
        contenu: [
            "Compétences informatiques appliquées aux fonctions administratives",
            "Gestion et rédaction de messages",
            "Maîtrise des fonctions avancées de Microsoft Office"
        ],
        cible: [
            "Assistants et secrétaires de direction",
            "Secrétaires expérimentés",
            "Assistants débutants"
        ]
    },
    {
        titre: "Amélioration de la qualité de l'accueil et du service",
        objectifs: "Développer un accueil professionnel et une communication de qualité avec les clients.",
        contenu: [
            "Importance de l'accueil et de la communication",
            "Styles et positionnements dans la relation client",
            "Techniques pour réussir l'accueil physique et téléphonique"
        ],
        cible: ["Ouvert à tous les professionnels de l'accueil"]
    },
    {
        titre: "Gestion administrative efficace",
        objectifs: "Apprendre les meilleures pratiques pour une gestion administrative optimale.",
        contenu: [
            "Organisation et gestion du temps",
            "Gestion de documents et archivage",
            "Outils numériques pour l'administration"
        ],
        cible: ["Assistants administratifs", "Secrétaires", "Responsables de bureau"]
    },
    {
        titre: "Maîtriser le bureautique avancé avec Microsoft Office",
        objectifs: "Approfondir les compétences dans l’utilisation des outils Microsoft Office.",
        contenu: [
            "Jour 1 : Techniques avancées avec Microsoft Word",
            "Jour 2 : Formules avancées et tableaux croisés dynamiques avec Excel",
            "Jour 3 : Présentations professionnelles et animations avec PowerPoint"
        ],
        cible: ["Professionnels ayant des bases en bureautique"]
    },
    {
        titre: "Gestion de projet et outils de planification",
        objectifs: "Former les participants à gérer des projets efficacement en utilisant des outils adaptés.",
        contenu: [
            "Introduction à la gestion de projet",
            "Utilisation de Microsoft Project ou d’outils en ligne (Trello, Asana)",
            "Élaboration de plannings et suivi des tâches"
        ],
        cible: ["Chefs de projet", "Coordinateurs", "Responsables d’équipe"]
    },
    {
        titre: "Communication professionnelle et rédaction efficace",
        objectifs: "Améliorer les compétences de communication écrite et orale dans un contexte professionnel.",
        contenu: [
            "Techniques de rédaction de documents administratifs et commerciaux",
            "Rédaction de courriels et rapports professionnels",
            "Techniques de présentation et prise de parole en public"
        ],
        cible: ["Tous les professionnels souhaitant améliorer leur communication"]
    }
];

export const comptabilite = "Cerner les nouveaux outils de gestion de comptable. Et aussi les " +
    "notions pointues en Banque Finance, grâce à ces formations conçues " +
    "par nos experts pour vous permettre d’être à pointe."

export const comptabiliteModules = [
    {
        titre: "Initiation à la comptabilité pour non-comptables",
        objectifs: [
            "Offrir aux professionnels non-comptables une compréhension de base des principes comptables.",
        ],
        contenu: [
            "Introduction aux notions de base de la comptabilité.",
            "Tenue de livres, enregistrements comptables et gestion des documents financiers.",
            "Lecture et analyse des principaux états financiers (bilan, compte de résultat, flux de trésorerie).",
        ],
        cible: ["Entrepreneurs", "managers non-comptables", "dirigeants de PME."]
    },
    {
        titre: "Manager, maîtriser la comptabilité analytique et la gestion des coûts",
        objectifs: [
            "Former les professionnels à analyser les coûts et à utiliser la comptabilité analytique pour améliorer la rentabilité.",
        ],
        contenu: [
            "Différentes méthodes de calcul des coûts : coûts complets, coûts variables, coûts directs.",
            "Analyse des écarts budgétaires et contrôle de gestion.",
            "Utilisation de la comptabilité analytique pour la prise de décision.",
        ],
        cible: ["Directeurs financiers", "contrôleurs de gestion", "comptables d’entreprise."]
    },
    {
        titre: "Comptabilité et fiscalité des entreprises",
        objectifs: [
            "Apporter une expertise en matière de fiscalité et de comptabilité pour la gestion efficace des obligations fiscales.",
        ],
        contenu: [
            "Principes fiscaux applicables aux entreprises.",
            "Calcul et déclaration de la TVA, de l’impôt sur les sociétés et des autres contributions fiscales.",
            "Fiscalité locale et internationale : optimiser la gestion fiscale.",
            "Cas pratiques de déclaration fiscale.",
        ],
        cible: ["Responsables comptables", "directeurs financiers", "chefs d’entreprises."]
    },
    {
        titre: "Normes IFRS et comptabilité internationale",
        objectifs: [
            "Former les entreprises internationales à la mise en œuvre et au respect des normes IFRS.",
        ],
        contenu: [
            "Présentation des principales normes IFRS et leur application.",
            "Différences entre les normes IFRS et les normes comptables locales.",
            "Comment préparer des états financiers conformes aux IFRS.",
            "Études de cas sur des entreprises appliquant les IFRS.",
        ],
        cible: ["Comptables", "auditeurs", "responsables financiers d’entreprises internationales."]
    },
    {
        titre: "Audit financier et contrôle interne",
        objectifs: [
            "Former les professionnels à la réalisation d’audits financiers efficaces et à la mise en place d’un contrôle interne solide.",
        ],
        contenu: [
            "Processus et méthodologie d’un audit financier.",
            "Techniques de contrôle interne et gestion des risques financiers.",
            "Identification et prévention des fraudes comptables.",
            "Études de cas d’audit et contrôle interne dans des entreprises.",
        ],
        cible: ["Auditeurs", "responsables financiers", "directeurs de la conformité."]
    },
    {
        titre: "Optimisation de la gestion de la trésorerie d’entreprise",
        objectifs: [
            "Aider les professionnels à gérer efficacement la trésorerie pour optimiser la liquidité.",
        ],
        contenu: [
            "Principes de base de la gestion de la trésorerie.",
            "Techniques de prévision des flux de trésorerie.",
            "Gestion des encaissements et paiements.",
            "Analyse de la rentabilité et des liquidités.",
        ],
        cible: ["Trésoriers", "directeurs financiers", "comptables."]
    },
    {
        titre: "Comptabilité pour les PME",
        objectifs: [
            "Adapter les outils comptables aux petites et moyennes entreprises pour une gestion financière optimale.",
        ],
        contenu: [
            "Simplification des processus comptables pour les PME.",
            "Tenue des livres, gestion des charges et suivi des recettes.",
            "Élaboration des états financiers pour les PME.",
            "Gestion de la trésorerie et des impôts pour les petites entreprises.",
        ],
        cible: ["Chefs d’entreprises", "entrepreneurs", "comptables de PME."]
    },
    {
        titre: "La pratique de la comptabilité bancaire et les normes IFRS",
        objectifs: [
            "Situer l'activité bancaire dans son environnement économique et réglementaire.",
            "Maîtriser la traduction comptable des opérations bancaires.",
            "Évaluer leur impact sur la situation financière.",
            "Utiliser les IFRS dans un environnement bancaire.",
            "Comprendre les états financiers d’établissements bancaires publiés en IFRS.",
        ],
        contenu: [
            "Le contexte et les enjeux des normes IFRS.",
            "Les normes de reconnaissance des revenus.",
            "Le traitement des stocks - IAS 2.",
            "Les normes concernant les actifs immobilisés.",
            "Les normes concernant les contrats de location - IAS 17.",
            "Dépréciations d’actifs.",
            "Les normes concernant les passifs.",
            "Méthodes comptables et changements comptables - IAS 8.",
            "Les normes de consolidation.",
        ],
        cible: ["Responsables et collaborateurs des services comptables et financiers."]
    },
    {
        titre: "Le métier de la conformité dans la banque",
        objectifs: [
            "Comprendre sa mission, ses objectifs et ses activités.",
            "Identifier les faiblesses du dispositif et formuler des recommandations.",
            "Ressortir avec des actions clés pour améliorer son quotidien.",
        ],
        cible: ["Auditeurs internes", "responsables conformité", "managers conformité."]
    },
    {
        titre: "Les responsables paie : organiser et fiabiliser la paie",
        objectifs: [
            "Comprendre les règles indispensables en paie.",
            "Proposer des méthodes efficaces pour optimiser la performance du service paie.",
            "Réfléchir à des contrôles adaptés afin de fiabiliser la paie.",
        ],
        contenu: [
            "Lire et interpréter le bulletin de paie.",
            "Calculer la rémunération.",
            "Rémunérer les heures travaillées.",
            "Indemniser les absences.",
            "Gérer les avantages en nature et frais professionnels.",
            "Calculer les cotisations sociales.",
            "Calculer les exonérations de charges.",
        ],
        cible: ["Fiscalistes", "comptables", "RH."]
    },
    {
        titre: "Élaborer votre budget de A à Z",
        objectifs: [
            "Construire et suivre son budget.",
            "Maîtriser les principales techniques de prévisions.",
        ],
        contenu: [
            "Définition et rôles des différents budgets.",
            "Principales étapes de construction.",
            "Détermination des coûts fixes et variables.",
            "Mensualisation.",
            "Suivi et contrôle du budget.",
        ],
        cible: ["Ouvert à tous les publics."]
    },
];


export const logistique = "Comprendre la logistique, la chaine d’approvisionnement, son optimisation, les meilleurs pratiques d’achats est essentielle. D’où nos offres de formations."

export const logistiqueModules = [
    {
        titre: "Introduction à la gestion logistique",
        objectifs: [
            "Offrir une compréhension des principes fondamentaux de la logistique et de la chaîne d'approvisionnement.",
        ],
        contenu: [
            "Concepts de base de la logistique et de la chaîne d’approvisionnement.",
            "Rôles et fonctions de la logistique dans l’entreprise.",
            "Gestion des stocks et approvisionnement.",
            "Introduction à la logistique durable.",
        ],
        cible: ["Nouveaux professionnels", "équipes de vente","managers non spécialisés."]
    },
    {
        titre: "Gestion des opérations logistiques",
        objectifs: [
            "Optimiser les processus opérationnels pour améliorer l’efficacité logistique.",
        ],
        contenu: [
            "Planification des opérations logistiques.",
            "Gestion des stocks et méthodes de réapprovisionnement.",
            "Techniques de gestion des transports et de la distribution.",
            "Mesures de performance logistique et indicateurs clés.",
        ],
        cible: ["Responsables logistiques", "managers de production", "opérationnels."]
    },
    {
        titre: "Logistique internationale et gestion des chaînes d’approvisionnement",
        objectifs: [
            "Comprendre les enjeux et défis de la logistique à l'échelle mondiale.",
        ],
        contenu: [
            "Aspects juridiques et réglementaires de la logistique internationale.",
            "Stratégies de gestion des chaînes d'approvisionnement mondiales.",
            "Gestion des risques et des incertitudes dans les opérations internationales.",
            "Études de cas sur la logistique internationale.",
        ],
        cible: ["Responsables logistiques", "directeurs d'approvisionnement","managers de projets internationaux."]
    },
    {
        titre: "Optimisation de la chaîne d’approvisionnement",
        objectifs: [
            "Apprendre à identifier les inefficacités et à mettre en œuvre des solutions d’optimisation.",
        ],
        contenu: [
            "Analyse des flux de la chaîne d'approvisionnement.",
            "Méthodes d'optimisation des coûts et des délais.",
            "Outils et technologies pour la gestion de la chaîne d'approvisionnement (ERP, WMS).",
            "Mise en œuvre d'indicateurs de performance pour le suivi des améliorations.",
        ],
        cible: ["Responsables logistiques", "chefs de projet", "analystes."]
    },
    {
        titre: "Logistique durable et responsabilité sociale",
        objectifs: [
            "Intégrer des pratiques durables dans la gestion logistique pour réduire l'impact environnemental.",
        ],
        contenu: [
            "Principes de la logistique durable.",
            "Stratégies pour réduire l’empreinte carbone dans la chaîne d’approvisionnement.",
            "Cas pratiques de mise en œuvre de la durabilité dans les opérations logistiques.",
            "Lien entre logistique durable et performance économique.",
        ],
        cible: ["Responsables logistiques", "directeurs RSE", "toute personne intéressée par la durabilité."]
    },
    {
        titre: "La SCM (Supply Chain Management) : gestion de la chaîne logistique",
        objectifs: [
            "Comprendre les enjeux de l’optimisation des flux et des stocks.",
            "Appréhender les difficultés de l’optimisation des flux et des stocks.",
            "Structurer votre chaîne logistique de façon optimale.",
            "Réduire vos coûts et optimiser vos activités.",
            "Améliorer la performance de la gestion de vos entrepôts et de vos stocks.",
        ],
        contenu: [
            "Comprendre et s'adapter à l'évolution de la logistique.",
            "Appréhender la gestion des opérations d'un site logistique.",
            "Identifier le positionnement d'un site au sein d'un réseau de distribution.",
            "Mener une optimisation des opérations et des flux d'information.",
            "Optimisation des ressources et du management au quotidien.",
        ],
        cible: [
            "Cadres de direction logistique.",
            "Responsables logistique.",
            "Responsables de plate-forme ou d'entrepôt.",
            "Cadres des services études logistiques.",
            "Gestionnaires souhaitant parfaire leurs connaissances.",
        ]
    },
    {
        titre: "Les meilleures pratiques achats",
        objectifs: [
            "Maîtriser les étapes du processus achats.",
            "Tirer parti des meilleures pratiques et outils achats.",
            "Gérer les relations avec les différents interlocuteurs.",
            "Anticiper et gérer les risques à l’achat.",
            "Accéder à un référentiel de bonnes pratiques.",
            "Limité les risques inhérents à l’acte d’achat.",
            "Fiabiliser les relations avec les fournisseurs.",
            "Maîtriser les coûts.",
            "Savoir vendre la valeur ajoutée de la fonction.",
        ],
        contenu: [
            "Fonction achat et compétences de l'acheteur.",
            "Les fournisseurs.",
            "Consulter et analyser les offres.",
            "Négocier.",
            "Contractualiser.",
            "Suivre la performance.",
        ],
        cible: ["Toute personne souhaitant acquérir les bases de la fonction achat et professionnaliser sa démarche achat."]
    },
];

export const travail = "Maitriser les notions du droit du travail pour les RH, les professionnels. C’est cela que nous vous proposons.."

export const travailModules = [
    {
        titre: "Les bases du droit du travail",
        objectifs: [
            "Comprendre les principes fondamentaux du droit du travail et les obligations légales des employeurs et employés.",
        ],
        contenu: [
            "Introduction aux contrats de travail (CDD, CDI, intérim).",
            "Temps de travail, congés, et rémunération.",
            "Droits et obligations des employeurs et employés.",
            "Les différentes formes de licenciement.",
        ],
        cible: ["Managers", "responsables RH", "chefs d’entreprise."]
    },
    {
        titre: "La gestion des relations de travail et des conflits",
        objectifs: [
            "Apprendre à gérer efficacement les conflits individuels et collectifs dans le respect des lois du travail.",
        ],
        contenu: [
            "Prévention des conflits au travail.",
            "Les différentes procédures disciplinaires (avertissement, mise à pied, licenciement).",
            "Les droits syndicaux et la gestion des grèves.",
            "Résolution des litiges par la médiation et les tribunaux.",
        ],
        cible: ["Managers", "DRH", "avocats spécialisés en droit du travail"]
    },
    {
        titre: "Droit du travail et conformité légale pour les ressources humaines",
        objectifs: [
            "S’assurer que les processus RH sont conformes aux réglementations en vigueur.",
        ],
        contenu: [
            "Recrutement, contrats de travail et clauses spécifiques (clause de non-concurrence, mobilité).",
            "Santé et sécurité au travail : obligations légales de l’employeur.",
            "Négociation et rédaction d’accords collectifs.",
            "Procédures de licenciement et gestion des départs.",
        ],
        cible: ["Responsables RH", "juristes d’entreprise", "recruteurs."]
    },
    {
        titre: "Les réformes du droit du travail : comprendre les évolutions législatives",
        objectifs: [
            "Informer les participants sur les dernières évolutions législatives et leur impact sur les pratiques de l’entreprise.",
        ],
        contenu: [
            "Présentation des réformes récentes du droit du travail.",
            "Adaptation des contrats et des politiques internes aux nouvelles lois.",
            "Évolutions des droits des salariés : télétravail, égalité professionnelle, et rémunération.",
            "Impacts sur la gestion des ressources humaines et des relations sociales.",
        ],
        cible: "DRH, directeurs juridiques, et dirigeants d’entreprise."
    },
    {
        titre: "Droit du travail international et gestion des expatriés",
        objectifs: [
            "Apprendre à gérer les contrats de travail et les obligations légales des employés à l’international.",
        ],
        contenu: [
            "Contrats de travail internationaux et détachement de salariés.",
            "Droits et obligations des expatriés : rémunération, fiscalité, sécurité sociale.",
            "Différences entre les droits du travail locaux et internationaux.",
            "Gestion des mobilités internationales dans un cadre légal.",
        ],
        cible: ["Responsables de mobilité internationale", "DRH", "responsables juridiques."]
    },
];


export const marketing = "Le recours au marketing s’avère nécessaire pour toute entreprise. Trouvez ici nos formations dans le domaine."

export const marketingModules = [
    {
        titre: "Comprendre l’essentiel du marketing digital",
        objectifs: [
            "Fournir une compréhension des fondamentaux du marketing digital et de ses outils.",
        ],
        contenu: [
            "Les bases du marketing digital : SEO, SEM, réseaux sociaux.",
            "Création de contenu efficace et stratégie de marque en ligne.",
            "Analyse des performances et outils d'évaluation.",
            "Élaboration d'un plan de marketing digital simple.",
        ],
        cible: ["Professionnels débutants en marketing", "petites entreprises."]
    },
    {
        titre: "Stratégies avancées de marketing et de branding",
        objectifs: [
            "Aider les professionnels à développer des stratégies de marque solides et à améliorer leur positionnement sur le marché.",
        ],
        contenu: [
            "Analyse de marché et identification des segments cibles.",
            "Développement d'une proposition de valeur unique.",
            "Techniques de branding et storytelling.",
            "Mise en œuvre d'une stratégie de communication intégrée.",
        ],
        cible: ["Marketeurs expérimentés", "responsables de la communication."]
    },
    {
        titre: "Marketing de contenu et inbound marketing",
        objectifs: [
            "Former les professionnels à attirer et convertir des clients grâce à une stratégie de contenu efficace.",
        ],
        contenu: [
            "Création de contenu pertinent et engageant.",
            "Techniques d’inbound marketing : lead nurturing et conversion.",
            "Utilisation des outils d'automatisation marketing.",
            "Analyse et optimisation des performances du contenu.",
        ],
        cible: "Responsables marketing, créateurs de contenu."
    },
    {
        titre: "Analyse des données et marketing analytique",
        objectifs: [
            "Aider les professionnels à utiliser les données pour prendre des décisions marketing éclairées.",
        ],
        contenu: [
            "Introduction à l'analyse des données en marketing.",
            "Outils et techniques d'analyse (Google Analytics, CRM).",
            "Interprétation des données et prise de décision.",
            "Études de cas pratiques d'analyses réussies.",
        ],
        cible: ["Marketeurs", "analystes de données", "décideurs."]
    },
    {
        titre: "Marketing relationnel et fidélisation client",
        objectifs: [
            "Former les professionnels à établir des relations durables avec les clients pour augmenter la fidélisation.",
        ],
        contenu: [
            "Concepts de base du marketing relationnel.",
            "Techniques pour construire une relation client durable.",
            "Mise en place de programmes de fidélisation.",
            "Utilisation des retours clients pour améliorer l’offre.",
        ],
        cible: ["Responsables de la relation client", "équipes commerciales."]
    },
];


export const droitAffaire = "La pratique du commerciale a découlé aux droit des affaires. " +
    "S’initier aux droit des affaires, maitriser les outils sont essentiels. " +
    "Nos experts vous ont conçu ce programme de formation pour la " +
    "maitrise du droit des affaires."

export const droitAffairesModules = [
    {
        titre: "L’Essentiel du droit des affaires",
        objectifs: [
            "Offrir une vue d’ensemble des principes fondamentaux du droit des affaires pour les professionnels non juristes.",
        ],
        contenu: [
            "Principes généraux du droit des sociétés.",
            "Types de structures juridiques d’entreprises.",
            "Droits et obligations des actionnaires et des dirigeants.",
            "Aspects légaux liés aux contrats commerciaux.",
        ],
        cible: ["Entrepreneurs", "managers", "cadres non juristes."]
    },
    {
        titre: "Rédaction et négociation des contrats commerciaux",
        objectifs: [
            "Apprendre les compétences clés pour rédiger et négocier efficacement des contrats commerciaux.",
        ],
        contenu: [
            "Les différentes clauses contractuelles et leurs enjeux.",
            "Rédaction de contrats : bonnes pratiques et pièges à éviter.",
            "Techniques de négociation contractuelle.",
            "Gestion des litiges et des clauses de résolution des conflits.",
        ],
        cible: ["Responsables commerciaux", "directeurs juridiques", "entrepreneurs."]
    },
    {
        titre: "Droit des sociétés : création, gestion et transformation",
        objectifs: [
            "Comprendre les différentes structures juridiques des sociétés et maîtriser les aspects liés à la gestion et la transformation d’une entreprise.",
        ],
        contenu: [
            "Création de sociétés : choix de la structure juridique.",
            "Gestion et administration des sociétés.",
            "Fusions, acquisitions, et transformations d’entreprises.",
            "Responsabilités des dirigeants et des administrateurs.",
        ],
        cible: ["Dirigeants d’entreprise", "directeurs financiers", "responsables juridiques."]
    },
    {
        titre: "Droit des affaires internationales",
        objectifs: [
            "Former les professionnels aux aspects légaux liés aux transactions et opérations internationales.",
        ],
        contenu: [
            "Les contrats internationaux : spécificités et clauses essentielles.",
            "Gestion des risques juridiques dans le commerce international.",
            "Règlement des litiges internationaux : arbitrage et médiation.",
            "Règlementations douanières et commerciales internationales.",
        ],
        cible: ["Cadres juridiques", "directeurs export", "professionnels impliqués dans les affaires internationales."]
    },
    {
        titre: "Droit de la concurrence et de la propriété intellectuelle",
        objectifs: [
            "Maîtriser les règles de concurrence et la protection de la propriété intellectuelle dans le cadre des affaires.",
        ],
        contenu: [
            "Règlementations en matière de concurrence (pratiques anticoncurrentielles, abus de position dominante).",
            "Aspects légaux de la propriété industrielle (marques, brevets, designs).",
            "Protection des droits d’auteur et des logiciels.",
            "Litiges liés à la concurrence et à la propriété intellectuelle.",
        ],
        cible: ["Juristes", "responsables marketing", "cadres en innovation."]
    },
    {
        titre: "Fiscalité des entreprises",
        objectifs: [
            "Former les participants aux règles fiscales applicables aux entreprises et optimiser leur gestion fiscale.",
        ],
        contenu: [
            "Principes généraux de la fiscalité des entreprises.",
            "Impôt sur ",
        ]
    },
];


export const marchePublic = "La pratique des marchés publics est soumise à des règles. Ces règles " +
    "qui ne cessent d’évoluer, les comprendre, et les maitriser sont ce " +
    "que nous mettons à votre disposition à travers nos formations."

export const marchePublicModules = [
    {
        titre: "Le nouveau cadre de passation de marchés de la Banque Mondiale et de la BAD",
        objectifs: [
            "Comprendre la nouvelle vision de la Banque mondiale en matière de passation et de suivi de l’exécution des marchés publics.",
            "Prendre connaissance des changements majeurs apportés par le nouveau cadre.",
            "Se familiariser avec les innovations apportées par le nouveau cadre.",
            "Comprendre et se familiariser avec les techniques et outils de préparation de la stratégie de développement (PPSD) et l’utilisation du système de suivi (STEP).",
            "Avoir une meilleure maîtrise des projets/programmes financés par la BAD et la Banque Mondiale.",
        ],
        cible: ["Personnels des projets"," membres des commissions des marchés", "agents et membres des commissions des marchés des établissements publics", "agents et membres des organes de contrôle."]
    },
    {
        titre: "L’essentiel sur les marchés publics",
        objectifs: [
            "Familiariser les participants avec les concepts fondamentaux des marchés publics et leur réglementation.",
        ],
        contenu: [
            "Cadre juridique des marchés publics.",
            "Types de marchés publics et procédures d’attribution.",
            "Règles de transparence et de concurrence.",
            "Étapes clés d’un appel d’offres.",
        ],
        cible: ["Nouveaux entrants dans le domaine des marchés publics", "entrepreneurs", "PME."]
    },
    {
        titre: "Rédaction d'offres pour les marchés publics",
        objectifs: [
            "Former les participants à élaborer des offres compétitives pour répondre aux appels d’offres.",
        ],
        contenu: [
            "Analyse des documents de l’appel d’offres.",
            "Stratégies de rédaction d’une offre technique et financière.",
            "Mise en valeur des atouts de l’entreprise.",
            "Gestion des délais et des procédures de soumission.",
        ],
        cible: ["Responsables de la rédaction des offres", "chefs de projet", "entrepreneurs."]
    },
    {
        titre: "Audit et contrôle des marchés publics",
        objectifs: [
            "Former les participants aux pratiques d’audit et de contrôle dans les marchés publics.",
        ],
        contenu: [
            "Normes et pratiques d’audit des marchés publics.",
            "Identification des risques et gestion des non-conformités.",
            "Élaboration de rapports d’audit.",
            "Études de cas pratiques.",
        ],
        cible: ["Auditeurs internes", "contrôleurs de gestion", "responsables de la conformité."]
    },
    {
        titre: "Innovations et bonnes pratiques dans les marchés publics",
        objectifs: [
            "Présenter les dernières tendances et innovations dans le domaine des marchés publics.",
        ],
        contenu: [
            "Nouvelles technologies et digitalisation des marchés publics.",
            "Développement durable et marchés publics responsables.",
            "Études de cas sur des pratiques innovantes.",
            "Réseautage et partage d’expériences entre professionnels.",
        ],
        cible: ["Responsables de l’achat public", "chefs de projet", "décideurs."]
    },
];


export const qhse = "La santé au travail, la sécurité, la qualité hygiène, et le " +
    "développement durable sont des formations conçues par nos " +
    "experts, en vue de répondre aux besoins actuels des organismes."

export const qhseModules = [
    {
        titre: "Sensibilisation à la santé et sécurité au travail (SST)",
        objectifs: [
            "Sensibiliser les participants aux enjeux de la santé et de la sécurité au travail pour réduire les risques d’accidents."
        ],
        contenu: [
            "Les bases de la santé et sécurité au travail.",
            "Identification des dangers et évaluation des risques.",
            "Comportements sécuritaires et prévention des accidents.",
            "Introduction aux obligations légales en SST."
        ],
        cible: ["Tous les employés, nouveaux et anciens."]
    },
    {
        titre: "Gestion des risques professionnels",
        objectifs: [
            "Former les responsables à l’évaluation et à la gestion des risques professionnels dans leur environnement de travail."
        ],
        contenu: [
            "Analyse des risques professionnels : méthodes et outils.",
            "Mesures préventives et correctives à mettre en place.",
            "Techniques de communication des risques aux équipes.",
            "Cas pratiques et retour d’expériences."
        ],
        cible: ["Responsables RH", "managers", "coordinateurs SST."]
    },
    {
        titre: "Gestion du stress et des risques psychosociaux (RPS)",
        objectifs: [
            "Former les participants à la gestion du stress et à la prévention des risques psychosociaux au travail."
        ],
        contenu: [
            "Identification des signes de stress et de burnout.",
            "Techniques de gestion du stress et des conflits.",
            "Outils pour prévenir les risques psychosociaux (harcèlement, surcharge de travail).",
            "Mise en place d’une politique de bien-être au travail."
        ],
        cible: ["Managers", "responsables RH", "tout employé en situation de stress élevé."]
    },
    {
        titre: "Audit interne en santé et sécurité au travail",
        objectifs: [
            "Former les participants à la réalisation d’audits internes pour évaluer et améliorer la conformité SST de leur entreprise."
        ],
        contenu: [
            "Introduction aux principes et méthodologies de l’audit SST.",
            "Identification des non-conformités et mise en place de mesures correctives.",
            "Outils de suivi et d'amélioration continue en SST.",
            "Réalisation d’un audit pratique en entreprise."
        ],
        cible: ["Responsables SST", "auditeurs internes", "managers HSE."]
    },
    {
        titre: "Auditeur interne QHSE",
        objectifs: [
            "Former des auditeurs internes capables d'évaluer la conformité des systèmes QHSE aux normes en vigueur."
        ],
        contenu: [
            "Méthodologies d’audit selon les normes ISO 9001, 14001, et 45001.",
            "Planification et conduite d’audits internes QHSE.",
            "Rédaction de rapports d’audit et formulation de recommandations.",
            "Suivi des actions correctives et préventives."
        ],
        cible: ["Auditeurs internes", "responsables QHSE", "managers qualité."]
    },
    {
        titre: "Gestion des risques professionnels et environnementaux",
        objectifs: [
            "Former les participants à la gestion des risques liés à la santé, à la sécurité et à l’environnement au sein de l’entreprise."
        ],
        contenu: [
            "Identification des risques professionnels et environnementaux.",
            "Analyse des risques et évaluation des impacts.",
            "Mesures de prévention et plans d’actions correctifs.",
            "Conformité réglementaire et obligations légales."
        ],
        cible: ["Responsables QHSE", "chefs d’équipes", "managers de production."]
    },
    {
        titre: "Système de management environnemental (ISO 14001)",
        objectifs: [
            "Former les participants à la mise en œuvre et à la gestion d’un système de management environnemental conforme à la norme ISO 14001."
        ],
        contenu: [
            "Introduction à la norme ISO 14001 et ses exigences.",
            "Analyse environnementale et gestion des aspects significatifs.",
            "Mise en place d’un système de management environnemental.",
            "Suivi et amélioration continue des performances environnementales."
        ],
        cible: ["Responsables QHSE", "auditeurs internes", "responsables environnementaux."]
    },
    {
        titre: "Gestion des déchets et réduction de l’empreinte écologique",
        objectifs: [
            "Sensibiliser les participants à la gestion des déchets et à la réduction de l’impact environnemental de leur entreprise."
        ],
        contenu: [
            "Identification des différents types de déchets industriels.",
            "Méthodes de tri et de gestion des déchets.",
            "Stratégies de réduction de l’empreinte carbone.",
            "Mise en place d’une politique environnementale dans l’entreprise."
        ],
        cible: ["Responsables environnementaux", "managers d’équipes", "ingénieurs HSE."]
    },
    {
        titre: "L’Essentiel du développement durable et à la responsabilité sociétale des entreprises (RSE)",
        objectifs: [
            "Sensibiliser les participants aux principes du développement durable et à l’intégration de la RSE dans la stratégie d’entreprise."
        ],
        contenu: [
            "Comprendre les enjeux globaux du développement durable.",
            "Introduction aux trois piliers du développement durable (économique, social, environnemental).",
            "Les principes de la responsabilité sociétale des entreprises (RSE).",
            "Stratégies pour intégrer la RSE dans les activités quotidiennes de l’entreprise."
        ],
        cible: ["Dirigeants", "managers", "responsables de projets RSE."]
    },
    {
        titre: "Stratégies de réduction de l’empreinte carbone",
        objectifs: [
            "Apprendre aux entreprises à mesurer et réduire leur empreinte carbone dans le cadre de la transition énergétique."
        ],
        contenu: [
            "Comprendre l’empreinte carbone et son impact sur l’environnement.",
            "Outils et méthodes de calcul de l’empreinte carbone.",
            "Techniques pour réduire les émissions de gaz à effet de serre (efficacité énergétique, recours aux énergies renouvelables).",
            "Cas pratiques et exemples d’entreprises ayant réduit leur empreinte carbone."
        ],
        cible:[ "Responsables techniques", "environnementaux", "dirigeants", "managers."]
    },
    {
        titre: "Économie circulaire et gestion des déchets en entreprise",
        objectifs: [
            "Former les entreprises à adopter une démarche d’économie circulaire en optimisant la gestion des ressources et des déchets."
        ],
        contenu: [
            "Introduction à l’économie circulaire : principes et enjeux.",
            "Réduction des déchets et valorisation des matières premières.",
            "Stratégies de recyclage et de réutilisation des matériaux.",
            "Mise en place d’une politique de gestion durable des déchets en entreprise."
        ],
        cible: ["Managers", "responsables logistiques", "équipes en charge de la gestion des déchets."]
    },
    {
        titre: "Leadership et innovation durable",
        objectifs: [
            "Encourager les dirigeants et responsables à intégrer l'innovation durable dans la stratégie d’entreprise pour un impact positif à long terme."
        ],
        contenu: [
            "Le rôle du leadership dans la promotion de la durabilité.",
            "Techniques et outils pour innover de manière durable.",
            "Exemples d’innovations durables réussies dans divers secteurs.",
            "Développement de projets internes en lien avec l’innovation durable."
        ],
        cible: ["Dirigeants", "managers de l’innovation", "responsables de développement durable."]
    }
];

export const hr = "Le travail d’un RH, c’est aussi la paie. Comprendre le processus de la " +
    "paie, et les contours administration RH est ce que nous vous proposons à travers le programme de formation."

export const hrModules = [
    {
        titre: "Maîtriser le processus de Paie",
        objectifs: [
            "Former les participants à la gestion complète et précise de la paie."
        ],
        contenu: [
            "Élaboration des bulletins de paie.",
            "Prise en compte des éléments variables (primes, heures supplémentaires, absences).",
            "Déclarations sociales et fiscales.",
            "Logiciels de paie et automatisation des processus."
        ],
        cible: ["Gestionnaires de paie", "comptables."]
    },
    {
        titre: "Politique de Rémunération et Avantages Sociaux",
        objectifs: [
            "Développer une politique de rémunération équitable et attrayante pour motiver les employés."
        ],
        contenu: [
            "Conception d’une grille salariale.",
            "Gestion des augmentations, primes et avantages en nature.",
            "Respect des obligations légales en matière de rémunération.",
            "Stratégies pour fidéliser les talents grâce aux avantages sociaux."
        ],
        cible: ["Directeurs RH", "responsables de la paie."]
    },
    {
        titre: "Droit Social et Législation du Travail pour les RH",
        objectifs: [
            "Maîtriser les aspects législatifs et réglementaires de la gestion des ressources humaines."
        ],
        contenu: [
            "Obligations légales des employeurs et des salariés.",
            "Gestion des congés, des arrêts maladie et des absences légales.",
            "Droit disciplinaire et procédures de licenciement.",
            "Actualité et réformes du droit du travail."
        ],
        cible: ["Juristes", "responsables RH", "managers."]
    }
];

export const cybersecurity = "L’évolution numérique exige aux États, organismes, entreprises, de " +
    "protéger leurs données, les systèmes d’information. Nous mettons à votre disposition des formations dans ce sens."

export const cybersecurityModules = [
    {
        titre: "Cybersécurité et Protection des Données",
        objectifs: [
            "Accompagnement à la mise en conformité sur la loi à la protection des données personnelles et données sensibles.",
            "Gouvernance sur la sécurité des données dans toute sa globalité.",
            "Comprendre le traitement et l’analyse des données.",
            "Augmenter la protection des utilisateurs en matière de données personnelles et sensibles, ainsi que leur accès."
        ],
        cible: [
            "DSI",
            "Informaticiens",
            "RH",
            "Managers",
            "Directeurs Généraux",
            "Délégué à la Protection des Données",
            "Responsables du Traitement des Données",
            "Autres"
        ]
    },
    {
        titre: "Audit et Contrôle de la Sécurité des S.I.",
        objectifs: [
            "Comprendre les mécanismes de sécurisation du système d’information.",
            "Maîtriser les principes et règles de gestion de la sécurité des SI.",
            "Analyser les besoins en sécurité du système d’information.",
            "Établir et entretenir le niveau de sécurité acceptable pour l’entreprise.",
            "Gérer le risque d’intrusion et de pénétration du SI."
        ],
        cible: [
            "Responsable d’entreprise maîtrisant l’outil informatique",
            "Spécialiste de la sécurité (Police, Militaire, etc.)",
            "Responsable ou opérateur dans une banque",
            "Juriste ou associé",
            "Auditeur des systèmes d’information",
            "Toute personne initiée ou non à la maîtrise du risque métier dans un système d’information."
        ]
    },
    {
        titre: "La Maîtrise des Aspects Juridiques de la Cybercriminalité",
        objectifs: [
            "Sensibiliser et renforcer les capacités des participants dans le domaine de la prévention de la cybercriminalité."
        ],
        cible: [
            "Directeurs administratifs",
            "Directeurs des Ressources Humaines",
            "Directeurs Techniques",
            "Juristes (magistrats, avocats, notaires, juristes d’entreprise, huissiers de justice, etc.)",
            "Informaticiens (Directeurs ou Chefs service informatique, Gestionnaires de systèmes d’information, Administrateurs de réseaux, Développeurs d’application, etc.)",
            "Comptables/Gestionnaires"
        ]
    }
];


export const suivi = "L’essentiel sur le suivi évaluation et programme de développement"

export const suiviEvaluationModules = [
    {
        titre: "L’essentiel sur le Suivi Évaluation et Programme de Développement",
        objectifs: [
            "Développer les capacités sur le suivi, l’évaluation des programmes et projets de développement, les approches y afférentes (notamment la GAR).",
            "Comprendre les besoins et défis liés au S&E basé sur les résultats en Afrique."
        ],
        contenu: [
            "Comprendre les principaux concepts sur le S&E.",
            "Développer les connaissances pratiques sur le S&E basé sur les résultats.",
            "Maîtriser les défis liés au S&E basé sur le genre en Afrique.",
            "Établir un réseau de collaboration et de partage de connaissances dans le domaine du suivi-évaluation."
        ],
        cible: [
            "Coordonnateurs de projets et de programmes.",
            "Cadres de la fonction publique, des collectivités territoriales, des agences de développement.",
            "Cadres du secteur privé et des ONG.",
            "Cadres techniques et administratifs, et autres personnes impliquées dans la supervision des projets et programmes.",
            "Étudiants avec ou sans expérience professionnelle."
        ]
    },
    {
        titre: "Conception et Gestion de Systèmes de Suivi et Évaluation",
        objectifs: "Former les participants à la conception et à la mise en place de systèmes efficaces de suivi-évaluation pour leurs organisations.",
        contenu: [
            "Élaboration de systèmes de suivi-évaluation intégrés.",
            "Mise en place de bases de données pour le suivi en temps réel.",
            "Analyse des performances des projets et ajustements en temps réel.",
            "Rétroaction et amélioration continue à partir des données de suivi."
        ],
        cible: [
            "Responsables de suivi-évaluation.",
            "Chefs de projets."
        ]
    },
    {
        titre: "Évaluation d’Impact des Projets de Développement",
        objectifs: "Former les professionnels à l’évaluation d’impact pour mesurer les effets à long terme des projets.",
        contenu: [
            "Méthodes quantitatives et qualitatives d’évaluation d’impact.",
            "Choix des indicateurs d’impact à long terme.",
            "Techniques d’analyse contre-factuelle et de comparaison.",
            "Rapports d’évaluation d’impact pour les parties prenantes et les bailleurs."
        ],
        cible: [
            "Évaluateurs professionnels.",
            "Cadres en développement.",
            "Consultants."
        ]
    }
];
