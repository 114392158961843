import { Sidebar } from 'flowbite-react';
import React, {useState, useEffect} from 'react';
import blogBanner from "../../assets/banners/blog.jpg";
import Banner from "../../components/layout/Banner";
import {collection, onSnapshot} from "firebase/firestore";
import {db} from "../../config/firebase";
import Spinner from "../../components/Spinner";

const ArticlePage = () => {
    const [article, setArticle] = useState("")
    const [openArt, setOpenArt] = useState<any>()
    const [loading, isLoading] = useState(false)
    const [articles, setArticles] = useState<any[]>([])

    useEffect(() => {
        isLoading(true)
        const subs = onSnapshot(collection(db, "articles"), (snapshot) => {
            let list: any[] = [];
            snapshot.docs.forEach((doc) => {
                list.push({_id: doc.id, ...doc.data()})
            })
            const groupedData = list.reduce((acc, current) => {
                const key = current.category;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(current);
                return acc;
            }, {});
            setArticles(list)
            isLoading(false)
        })

        return () => {

            subs()
        }

        window.scrollTo(0, 0)
    },[]);

    return (
        <>
            <Banner image={blogBanner} title={"Articles"}
                    description={"Consultez les articles de SONNI Magazine"}/>
           <div className="flex flex-row lg:m-10 max-sm:mt-7 max-sm:flex-col">
               {loading ? <div className="flex flex-1 mt-[20%] justify-center items-center text-center">
                       <Spinner/>
                   </div>
                   :
                   <div className=" mb-20">
                       <p className="text-lg text-primary font-bold mb-7 text-center">ARCHIVES</p>

                       {
                           <Sidebar className="lg:ml-12 justify-center items-center max-sm:w-full border-2">

                           <Sidebar.Items>
                               {articles.length > 0 &&
                                   articles.map(res => (
                                       <Sidebar.ItemGroup
                                           className="flex flex-col h-full justify-center items-center"
                                           onClick={() => {
                                               setArticle("")
                                               setOpenArt(res)
                                           }}>
                                           <Sidebar.Item>
                                               <button  className="flex flex-col">
                                                   <img
                                                       src={res.img}
                                                       key={res.img}
                                                       className="h-40 max-sm:w-full max-sm:h-80 rounded-xl"
                                                       alt="team-building-image"/>
                                                   <div className="grid grid-cols-1 text-wrap">
                                                       <p className="mt-3 text-[12px] text-center text-primary font-medium">{res.title}</p>
                                                   </div>
                                               </button>
                                           </Sidebar.Item>
                                       </Sidebar.ItemGroup>
                                   ))
                               }
                               <Sidebar.ItemGroup onClick={() => {
                                   setOpenArt("")
                                   setArticle("https://sonnimagazine.blogspot.com/2022/07/umaro-sissoco-embalo-un-leadership-fort.html")
                               }}>
                                   <Sidebar.Item className={"text-black hover:bg-primary rounded-0 cursor-pointer"}>
                                       <button>
                                           Juillet 2022
                                       </button>
                                   </Sidebar.Item>
                               </Sidebar.ItemGroup>
                                   <Sidebar.ItemGroup onClick={() => {
                                   setOpenArt("")
                                   setArticle("https://sonnimagazine.blogspot.com/2020/01/niger-attaque-terroriste-bilan-63.html")
                               }}>
                               <Sidebar.Item className={"text-black hover:bg-primary cursor-pointer"}>
                                   <button>
                                       Janvier 2020
                                   </button>
                               </Sidebar.Item>
                           </Sidebar.ItemGroup>


                           </Sidebar.Items>
                       </Sidebar>}
                   </div>
               }

               <div className="flex flex-1 lg:ml-10">
                   {article && <iframe
                       title={article}
                       width="100%"
                       height="750"
                       loading="lazy"
                       allowFullScreen
                       referrerPolicy="no-referrer-when-downgrade"
                       src={article}>
                   </iframe>}
                   {
                       openArt &&
                       <div className="flex flex-col bg-[#dddddd] justify-center items-center">
                           <h3 className="text-3xl text-center text-primary font-semibold mb-5">{openArt.title}</h3>
                           <img className="h-96 w-96 mb-5" src={openArt?.img} alt='img'/>
                           <div className="text-xl m-5" dangerouslySetInnerHTML={{__html: openArt.content}}/>

                       </div>
                   }

               </div>
           </div>
        </>
    );
};

export default ArticlePage;


