
import {address, email, mobile, presentation, telephone} from '../../assets/contents/homePage'
import logo from '../../assets/logos/logo-1.jpeg'

export default function Footer() {
    return (


        <footer className="bg-stone-900">
            <div className="mx-auto w-full p-4 py-6 lg:py-8">
                <div className="md:flex md:justify-between md:items-center md:w-[75%] md:ml-[12%]">
                    <div className="mb-6 md:mb-0 flex-1 mr-12">
                        <a href="/" className="flex items-center mb-6">
                            <img src={logo} className="h-12 me-3" alt="Sonni Logo" />
                            <span className="self-center text-xl font-semibold whitespace-nowrap text-white">SONNI GROUP</span>
                        </a>

                        <p className="text-justify text-sm text-gray-300">
                            Nous sommes un organisme de conseils et
                            de formation professionnelle continue. Implanté à Dakar, nous disposons d’une
                            expertise avérée en ingénierie pédagogique, en conseils et solutions : Relations
                            Internationales, Diplomatie, Organisation Managériale et Leadership.
                        </p>

                        <ul className="text-gray-300 mt-8">
                            <li className="mb-4">
                                <div className='flex mb-6'>
                                    <svg className="w-10 mr-12 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z" />
                                    </svg>
                                    <span>
                                        Adresse
                                        <p className="text-justify text-sm text-gray-300 mt-4">{address}</p>
                                    </span>
                                </div>

                            </li>

                            <li className="mb-4">
                                <div className='flex mb-6'>
                                    <svg className="w-8 mr-12 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z" />
                                    </svg>

                                    <span>
                                        Téléphone
                                        <p className="text-justify text-sm text-gray-300 mt-4">{telephone}</p>
                                    </span>
                                </div>

                            </li>
                            <li className="mb-4">
                                <div className='flex mb-6'>
                                    <svg className="w-8 mr-12 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z" />
                                    </svg>

                                    <span>
                                        Mobile
                                        <p className="text-justify text-sm text-gray-300 mt-4">{mobile}</p>
                                    </span>
                                </div>

                            </li>

                            <li className="mb-4">
                                <div className='flex mb-6'>
                                    <svg className="w-8 mr-12 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" />
                                    </svg>


                                    <span>
                                        Email
                                        <p className="text-justify text-sm text-gray-300 mt-4">{email}</p>
                                    </span>
                                </div>

                            </li>
                        </ul>
                    </div>

                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-300 uppercase">Menu</h2>
                            <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href="/team" className="hover:underline ">A propos</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/formations" className="hover:underline ">Formation</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/services" className="hover:underline">Services</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/certificats" className="hover:underline">Certificats & MBA</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/seminaires" className="hover:underline">Séminaires</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/team-building" className="hover:underline">Team Building</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/blog" className="hover:underline">Blog</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/contact" className="hover:underline">Contact</a>
                                </li>
                            </ul>
                        </div>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
                <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024  All Rights Reserved.
                    </span>
                    <div className="flex mt-4 sm:justify-center sm:mt-0">
                        <a href="https://www.facebook.com/cedmsonnidiplomatie?mibextid=LQQJ4d"
                           className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className="w-5 h-5" aria-hidden="true"
                                 viewBox="0 0 48 48">
                                <path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path>
                                <path fill="#fff"
                                      d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"></path>
                            </svg>
                            <span className="sr-only">Facebook page</span>
                        </a>

                        <a href="https://www.linkedin.com/company/cabinetsonni/"
                           className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" aria-hidden="true"
                                 viewBox="0 0 48 48">
                                <path fill="#0288D1"
                                      d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path>
                                <path fill="#FFF"
                                      d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"></path>
                            </svg>
                            <span className="sr-only">LinkedIn page</span>
                        </a>

                        <a href="https://www.instagram.com/cabinet_sonni?igsh=MXd1MWNrcGlmYnR6eA%3D%3D&utm_source=qr" className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className="w-5 h-5" aria-hidden="true"
                                 viewBox="0 0 48 48">
                                <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035"
                                                r="44.899" gradientUnits="userSpaceOnUse">
                                    <stop offset="0" stop-color="#fd5"></stop>
                                    <stop offset=".328" stop-color="#ff543f"></stop>
                                    <stop offset=".348" stop-color="#fc5245"></stop>
                                    <stop offset=".504" stop-color="#e64771"></stop>
                                    <stop offset=".643" stop-color="#d53e91"></stop>
                                    <stop offset=".761" stop-color="#cc39a4"></stop>
                                    <stop offset=".841" stop-color="#c837ab"></stop>
                                </radialGradient>
                                <path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)"
                                      d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path>
                                <radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54"
                                                r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)"
                                                gradientUnits="userSpaceOnUse">
                                    <stop offset="0" stop-color="#4168c9"></stop>
                                    <stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop>
                                </radialGradient>
                                <path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)"
                                      d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path>
                                <path fill="#fff"
                                      d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path>
                                <circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle>
                                <path fill="#fff"
                                      d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
                            </svg>
                            <span className="sr-only">Instagram account</span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>

    )
}
