export const formationSurMesure = "Pour vos besoins en formation continue, en tant que organisme expert en ingénierie pédagogique, nous sommes prêts à accompagner votre organisation ou entreprise pour les besoins de vos équipes en formation continue. En outre, notre Centre de formation vous " +
"accompagne via nos programmes de certifications et MBA conçus par des experts – formateurs, vous permettant de mieux croitre."

export const teamBuilding = "Nous sommes convaincus que les énergies inexploitées peuvent être exploitées à travers des moment de convivialité, d’animation, de jeux fun. Convaincus aussi, qu’un environnement saint, de confiance mutuelle entre collaborateurs permet d’explorer tous les horizons et de " +
"réaliser des grandes choses pour votre institution. Notre programme de team Building est là pour cela."

export const coaching = "Professionnels, Cadres ou Diplomates, libérez votre potentiel et devenez la meilleure version de vous-même : pour une croissance exceptionnelle. Notre système de coaching d’équipe ou individuel vous permettra d’atteindre votre plein potentiel."

export const organisationManagerQuality = "L’organisation managériale de vos services, la qualité des services est pour nous d’une importance capitale. D’où nous vous proposons notre accompagnement.."

export const lobbying = "Que vous soyez une organisation publique, parapublique , une ONG ou une entreprise désirant une expansion, une implantation, des facilitations, nous vous proposons notre accompagnement en mettant notre expertise de lobbying à votre disposition."

export const assistanceJuridique = "En tant que organisme intervenant dans le conseil en management, nous vous proposons des " +
    "services de conseil juridique, d’accompagnement juridique dans la réalisation de vos projets, " +
    "activités. Nos consultant en matière juridique sont des experts détenant plusieurs années " +
    "d’expérience pratique en Afrique et dans le monde."

export const gestionProjetComplex = "La mise à exécution de grands projets sur le continent africain, avec notre cabinet intervenant " +
    "dans le management, nous vous accompagnons de bout en bout jusqu’à la réalisation de votre " +
    "projet. Cela partout sur le continent."

export const mediationAffaire = "À SONNI, nous nous évertuons à vous accompagner dans la médiation des affaires pour " +
    "rendre facile la concrétisation de vos entreprises et projets."

export const communication = "Avec nos éminent consultants, Experts en communication et Coachs en Art Oratoire " +
    "fournissons à nos clients des services liés à la communication (Communication Interne ou " +
    "externe, Prise de Parole en Public, Animation d’Évènement)."


